<template>
  <div id="app">
    <MultiPageSurvey />
  </div>
</template>

<script>
import MultiPageSurvey from './components/MultiPageSurvey.vue'

export default {
  name: 'App',
  components: {
    MultiPageSurvey
  }
}
</script>
