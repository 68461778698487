<template>
  <div>
    <h1><a href="/">「路面電車無料デー」に関するアンケート調査</a></h1>
    <p style="text-align:center;color:red;" v-show="isSurveyPreview">
      回答内容の確認をお願いします。<br>
      以下の内容でよろしければ、画面一番下にある「この内容で送信する」を押してください。
    </p>
    <Survey :survey="survey" id="surveyContainer" v-if="!isEnd"/>
    <div v-if="isEnd && !isSurveyCompleted">
      <p style="text-align:center;">
      多くの方々にご協力いただき、<br>
アンケート調査は終了いたしました。
<br>
ありがとうございました。
</p>
    </div>
    <div v-if="isSurveyCompleted" class="view-complete">

      <div>


<div class="box">
アンケートにご回答いただいた御礼として、ジョルダン株式会社が提供するアプリ「乗換案内」で使用できる「モバイル版 路面電車１日乗車券（大人）のチケットコード」をプレゼントいたします。    
   
   <p style="color:red;text-align:center;">※令和4年10月30日の20時頃まで、チケットコードが正しく表示されておりませんでした。<br>
※ご迷惑をお掛けした方々には、この場を借りて深くお詫び申し上げます。誠に申し訳ございませんでした。
</p>
     <div class="ticket">
        {{ticketCode}}
     </div>

<div class="notice">
  ※この画面は、スクリーンショットなどで保存することをお勧めいたします<br>
※チケットコードは令和５年３月31日まで有効です
</div>

<hr>

<h3 style="text-align:center;">「モバイル版 路面電車１日乗車券」ご利用の流れ</h3>

<h4>①アプリ「乗換案内」をインストール</h4>
<p>
「<a href="https://apps.apple.com/jp/app/%E4%B9%97%E6%8F%9B%E6%A1%88%E5%86%85/id299490481
" target="_blank">AppStore</a>」もしくは「<a href="https://play.google.com/store/apps/details?id=jp.co.jorudan.nrkj" target="_blank">Playストア</a> 」からダウンロードしてください。  


</p>
<p>↓</p>
<h4>②利用登録</h4>
<p>ＪＩＤ（ジョルダンＩＤ）もしくはAppleIDまたはGoogleアカウントでログインしてください。</p>
<p class="note">※アプリを初めてお使いでアカウントがない方はJIDを新規登録してください。</p>
<p>↓</p>
<h4>③アプリ上でチケットを購入</h4>
「メニュー」→「チケット」→「札幌市電」から「路面電車１日乗車券」のチケットを購入します。<br>支払方法で「ジョルダンチケットコード」を選択し、12桁のジョルダンチケットコードを入力してください。
<p>↓</p>
<h4>④利用する日にチケットの使用を開始</h4>
<p>実際にチケットを使用する日に、アプリ内「メニュー」→「チケット」→「Myチケット」から当該チケットを選択し、「チケットを使う」をタップしてください。</p>

</div>

      </div>

      <div class="section">
            <h3>【お問い合わせ先】</h3>
    <h4>アンケートに関するお問い合わせ</h4>
    <p>
      （業務委託先）株式会社ドーコン交通部
      <a href="tel:011-801-1520" style="margin: 0 10px">TEL:011-801-1520</a>
    </p>
    <h4>乗車券等に関するお問い合わせ</h4>
    <p>
      一般財団法人札幌市交通事業振興公社
      <a style="margin: 0 10px" href="tel:011-251-0824">TEL:011-251-0824</a>
    </p>
      </div>
    
    </div>
  </div>
</template>

<script>
// import 'survey-core/defaultV2.min.css';
import 'survey-core/survey.min.css';
import '../assets/css/style.css';
import { StylesManager, Model ,surveyLocalization,settings } from 'survey-core';
import { Survey } from 'survey-vue-ui';
import "survey-core/survey.i18n";
import axios from "axios";
import surveyJson from "./question.js"
import dayjs from "dayjs"

StylesManager.applyTheme("default");

const jaLocale = surveyLocalization.locales["ja"];
//デフォルトは以下なので変更したい場合は上書きする
//https://github.com/surveyjs/survey-library/blob/master/src/localization/japanese.ts
jaLocale.requiredError = "必須項目です";
jaLocale.startSurveyText= "アンケートに回答する";
jaLocale.previewText="確認画面へ進む";
jaLocale.pagePrevText="戻る";
jaLocale.pageNextText="次のページへ進む";
jaLocale.completeText= "この内容で送信する";
jaLocale.editText="やり直す";

//スキップのトリガーを次へボタンを押してから実行する
settings.executeSkipTriggerOnValueChanged = false;



export default {
  name: 'MultiPageSurvey',
  components: {
    Survey
  },
  data() {
    const survey = new Model(surveyJson);

    survey.onComplete.add(this.displayResults);
    survey.locale = "ja";
    survey.onScrollingElementToTop.add((sender, options) => {
      //ページ遷移で戻る位置のidを指定
  options.elementId = 'surveyContainer'
});

//title,description をhtmlで表示
survey.onTextMarkdown.add(function (survey, options) {
  var str = options.text;
  options.html = str;
});


survey.onShowingPreview.add((sender,option)=>{
console.log(option)
  console.log(sender.getValue('q14-1'))
  if( sender.getValue('q14-1') === undefined){
    sender.setValue('q14-1','回答なし') 
  }
  this.isSurveyPreview = true
})

survey.onCurrentPageChanged.add((sender)=>{
  console.log(sender.state)
  console.log("onCurrentPageChanged")
  if(sender.state !== "preview"){
  this.isSurveyPreview = false
  }

})

var myCss = {
  container:'sv_container m-container'
};
survey.css = myCss;

    return {
      survey,
      surveyResults: null,
      isSurveyCompleted: survey.hasCookie || false ,
      debug:false,
      ticketCode:'------------',
      path:null,
      isSurveyPreview:false,
      isEnd:true
      // referer:null,
    }
  },
    created () {
  // this.getCount()
    

      // if(!this.debug){
      //   window.addEventListener("beforeunload", this.confirmSave);
      // }
      // if(!this.isSurveyCompleted){
      //   localStorage.removeItem("c")
      // }

   const hasCode = localStorage.getItem("c")
   const hasToken = localStorage.getItem("h")
   if(hasCode && this.isSurveyCompleted && hasToken){
    this.ticketCode = this.getCode(hasToken)//atob(hasCode)
   }else if(hasCode && this.isSurveyCompleted){
    this.ticketCode = atob(hasCode)
   }

  },
  mounted(){
    
  
    const searchParams = new URLSearchParams(window.location.search)
    this.path = searchParams.get('p')

  },
  destroyed () {
    //       if(!this.debug){
    // window.removeEventListener("beforeunload", this.confirmSave);
    //       }
  },
  methods: {
      confirmSave (event) {
        //アンケート回答中はページ遷移に確認ダイアログを出す
        if(!this.isSurveyCompleted && this.survey.pageCount !== 1){
          event.returnValue = "編集中のものは保存されませんが、よろしいですか？";
        }

    },
    async sha256(text){
    const uint8  = new TextEncoder().encode(text)
    const digest = await crypto.subtle.digest('SHA-256', uint8)
    return Array.from(new Uint8Array(digest)).map(v => v.toString(16).padStart(2,'0')).join('')
},
    saveCode(){
      localStorage
    },
    async getCount(){
        const limit = await axios.get('https://api.probep.com/api.php/records/c/1')
        if(limit?.data?.count <= 120){
          this.isEnd = false
        }else{
          this.isEnd = true
        }
    },
    getCode(token=null){
      if(!token){
        return null
      }
      axios.get('https://api.probep.com/api.php/records/codes?limit=1&filter=token,eq,'+token).then(res => {
        console.log(res.data)
        if(res.data.records.length){
          const getValue = res.data.records[0]?.code
          this.ticketCode = getValue || 'Not found'
          if(getValue){
            localStorage.setItem("c",  btoa(getValue));
          }
        }
  
      }).catch(() =>{
        this.ticketCode = 'Not found'
      })
    },
    //結果を送信
    async displayResults(sender) {
      const convertObj = {}
      if(sender.data){
        Object.keys(sender.data).forEach(k =>{
          if(Array.isArray(sender.data[k])){
          convertObj[k] = sender.data[k].join(",")
          }else{
            convertObj[k] = sender.data[k]
          }
        })
      }

      convertObj.posted_at = dayjs().format('YYYY-MM-DD HH:mm:ss')
      convertObj.ref = this.path

      const hashstr = await this.sha256(JSON.stringify(convertObj));
      localStorage.setItem("h",  hashstr);

      convertObj.token = hashstr;

      this.surveyResults = JSON.stringify(convertObj);
      
      axios.post('https://api.probep.com/api.php/records/answers',this.surveyResults,{
        "headers": {"content-type": "application/json",}
      }).then(res =>{
        console.log(res)
              this.isSurveyCompleted = true;
              this.getCode(hashstr)
              // 
      })


    }
  },
}
</script>
