import startHtml from "./pages/start.html"
import aboutHtml from "./pages/about.html"
import q1descHtml from "./pages/q1desc.html"
import mapHtml from "./pages/map.html"

//必須かどうかのフラグ デバッグ時はfalseにしておくと無回答でも確認できる
const requireFlag = true;
const section1 = {
      type: "html",
      html: "<h2 class='section1'> 路面電車無料デーについてお伺いします</h2>"
    }
const section2 = {
  type:"html",
  html:"<h2  class='section2'>路面電車<strong>無料デー以外の日</strong>についてお伺いします</h2>"
}

var createChoices = (option=[])=>{
  return option.map((t,i) =>{
    return {value:i+1,text:t}
  })
}

const mokuteki = createChoices(['通勤','通学','仕事','買物','飲食','観光','通院','趣味娯楽','その他'])
const area = createChoices(['①桑園エリア','②山鼻西エリア','③札幌駅周辺エリア','④大通駅周辺エリア',
'⑤すすきの駅周辺エリア','⑥中島公園・山鼻エリア','⑦創成東エリア','①～⑦以外'])

const moveChices = createChoices(['路面電車で移動した','徒歩で移動した','自転車で移動した','自動車、二輪車で移動した','路線バスで移動した'
,'地下鉄で移動した'
,'目的地を変更した'
,'外出しなかった'])

const useChices = createChoices(['増える','やや増える','変わらない','やや減る','減る','わからない'])
const ageChoices = createChoices(['１０歳未満','１０代','２０代','３０代','４０代','５０代','６０代','７０代','８０歳以上','回答しない'])
const addressChoices = createChoices(['札幌市中央区','札幌市内（中央区を除く）','北海道内（札幌市を除く）','北海道外','回答しない'])


const surveyJson = {
  cookieName: "train_uniquesurveyid",
  pages: [
    {
    elements: [
    {
      type: "html",
      html: startHtml,
    },
    ]
  },
  {
    elements: [
      {
      type: "html",
      html: "<h2 class='section1'>まず、路面電車無料デーについてお伺いします</h2>",
    }]
  },
  {
    elements: [
      section1,
      {
      type: "html",
      html: aboutHtml,
    }]
  },
  {elements: [
     section1,
      {
      name: "q1-1",
      title: "Q1 路面電車無料デーは何日ご利用されましたか。 <br>(10/30までに合計18日実施しています。)",
      type: "radiogroup",
      description:q1descHtml,
      choices: [
        { value: 1, text: "10日以上" },
        { value: 2, text: "5〜9日" },
        { value: 3, text: "3〜4日" },
        { value: 4, text: "1〜2日" },
      ],
      isRequired: requireFlag
    },
    ]},
    {elements:[section1 ,
       {
      name: "q2-1",
      title: "Q2 路面電車無料デーはどのように知りましたか。<br>※複数回答可",
      type: "checkbox",
      choices: [
        { value: 1, text: "ホームページ" },
        { value: 2, text: "SNS" },
        { value: 3, text: "報道（テレビ、新聞）" },
        { value: 4, text: "広報さっぽろ" },
        { value: 5, text: "車内広告" },
        { value: 6, text: "地下鉄駅掲示" },
        { value: 7, text: "デジタル広告" },
        { value: 8, text: "親族・友人などからの紹介" }, 
        { value: 9, text: "利用するまで知らなかった" },
      ],
      isRequired: requireFlag
    }]},
    {elements:[
      section1,
      {
     name: "q3-1",
     title: "Q3 路面電車無料デーは路面電車を利用するきっかけとなりましたか。",
     type: "radiogroup",
     choices: [
       { value: 1, text: "きっかけとなった" },
       { value: 2, text: "どちらともいえない" },
       { value: 3, text: "きっかけとならなかった" },
     ],
     isRequired: requireFlag
   }]},
   {elements:[
    section1 ,
    {
   name: "q4-1",
   title: "Q4 路面電車無料デーにおける、路面電車の主な乗車目的を教えてください。<br>（直近のご利用日、もしくは印象に残っているご利用日で結構です）",
   type: "radiogroup",
   choices: mokuteki,
   isRequired: requireFlag
 }]},
 {elements:[section1 ,
  {
 name: "q5-1",
 title: "Q5 Q4でお答えになった乗車目的で訪れたエリアを、以下の中から教えてください。<br>※複数回答可",
 type: "checkbox",
 description:`<div class="m-q-hidden">${mapHtml}</div>`,
 choices: area,
 isRequired: requireFlag
},
{type:"html",html:mapHtml}
]},
{elements:[section1  ,
 {
  name: "q6-1",
  title: "Q6 Q4.Q5でお答えになったご利用についてお伺いします。<br>路面電車無料デーが開催されていなかった場合、目的地へはどのように移動されましたか。<br>※複数回答可",
  type: "checkbox",
  choices: moveChices,
  isRequired: requireFlag
 }]},
 {elements:[section1,
 {
  name: "q7-1",
  title: "Q7 今後、日常におけるあなたの路面電車利用頻度はどのように変化しますか。",
  type: "radiogroup",
  choices: useChices,
  isRequired: requireFlag
 }]},
 {
  elements: [
    {
    type: "html",
    html: "<h2 class='section2'>続いて、路面電車無料デー以外の日についてお伺いします</h2>",
  }]
},
        {elements:[section2  ,
       {
      name: "q8-1",
      title: "Q8 今年の土曜・日曜・祝日における路面電車の利用状況を教えてください。",
      type: "radiogroup",
      choices: [
        { value: 1, text: "週に2日以上" },
        { value: 2, text: "週に1日" },
        { value: 3, text: "月に1〜3日" },
        { value: 4, text: "年に数日" },
        { value: 5, text: "利用しなかった" }
      ],
      isRequired: requireFlag
    }
    ]},
   {elements:[section2 ,
       {
      name: "q9-1",
      title: "Q9 今年の土曜・日曜・祝日における路面電車の主な乗車目的を教えてください。<br>(直近のご利用日、もしくは印象に残っているご利用日で結構です)",
      type: "radiogroup",
      choices: mokuteki,
      isRequired:requireFlag,
      visibleIf:'{q8-1} != 5',
      // enableIf:'{q8-1} != 4'
    }
    ]},
    {elements:[section2 ,
      {
     name: "q10-1",
     title: "Q10 Q9でお答えになった乗車目的で訪れたエリアを、以下の中から教えてください。<br>※複数回答可",
     type: "checkbox",
     description:`<div class="m-q-hidden">${mapHtml}</div>`,
     choices: area,
     isRequired:requireFlag,
     visibleIf:'{q8-1} != 5',
    //  enableIf:'{q8-1} != 4',
     
   },
   {type:"html",html:mapHtml}
   ]},
   {elements:[
   {
   name: "q11-1",
   title: "Q11 あなたの性別を教えてください。",
   type: "radiogroup",
   choices: [
     { value: 1, text: "男性" },
     { value: 2, text: "女性" },
     { value: 3, text: "その他" },
     { value: 4, text: "回答しない" }
   ],
   isRequired: requireFlag
 }
 ]}, 
 {elements:[
 {
 name: "q12-1",
 title: "Q12 あなたの年代を教えてください。",
 type: "radiogroup",
 choices: ageChoices,
 isRequired: requireFlag
}
]},
{elements:[
  {
  name: "q13-1",
  title: "Q13 あなたのお住まいを教えてください。",
  type: "radiogroup",
  choices: addressChoices,
  isRequired: requireFlag
 }
 ]},
 {elements:[
  {
  name: "q14-1",
  title: "Q14 路面電車無料デーに関するコメントがありましたらご記入ください。<br>※無記入可",
  type: "comment",
  isRequired: false,
  maxLength:3000
 }
 ]},
  ],
  triggers: [{
   type: "skip",
   expression: "{q8-1} = 5",
   gotoName: "q11-1"
  }],
  showQuestionNumbers: "off",
  requiredText:"",
  showPrevButton: false,
  firstPageIsStarted: true,
  completedHtml:"ご回答いただきありがとうございました。",
  showPreviewBeforeComplete: "showAnsweredQuestions",
  maxOthersLength:100,
  otherText:"その他",
  focusFirstQuestionAutomatic:false,
};

// surveyJson.pages.splice(4)

export default surveyJson
